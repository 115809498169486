import React from "react";
import PropTypes from "prop-types"
import "./_drawer.scss"
import {isMobile} from "../../../utils/MobileUtils";

export default class Drawer extends React.Component {

    getClassName() {
        const {show} = this.props;
        let className = "fetura-drawer";
        if (isMobile()) className += " mobile";
        if (show) className += " show";
        return className;
    }

    render() {
        const {name, children, onMouseEnter, onMouseLeave} = this.props;
        return (
            <div className={this.getClassName()} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="fetura-drawer-name">{name}</div>
                <div className="fetura-drawer-container">
                    {children}
                </div>
            </div>
        );
    }

}

Drawer.propTypes = {
    name: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired
}